import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    cohenMarginalia: Core.ImageAttributes;
    shamailaMarginalia: Core.ImageAttributes;
    cohenShamaila: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const { cohenMarginalia, shamailaMarginalia, cohenShamaila } = props.data;

  return (
    <Layout title="A Watershed Moment">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection
          marginalia={
            <Core.Caption>
              <Core.LeadIn>
                Interveiw by Brett McCabe
                <br />
                <br />
                Photography by Howard Korn
              </Core.LeadIn>
            </Core.Caption>
          }
        >
          <Core.LargeText fontStyle="sans">
            As Johns Hopkins SAIS celebrates its 75th anniversary, the magazine
            sat down with Dean Eliot A. Cohen and Student Government Association
            President Shamaila Ashraf for a wide-ranging conversation about our
            changing world, the school’s future, and curricular changes that
            will be nothing short of transformational.
          </Core.LargeText>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={cohenMarginalia}
                alt="Eliot A. Cohen"
              />
              <Core.Blockquote color="beige" author="Eliot Cohen">
                The school really is at a watershed in a number of ways. First
                and foremost, the world has changed and is changing, and so we
                [Johns Hopkins SAIS], for a whole bunch of reasons, actually
                have an opportunity to change.
              </Core.Blockquote>
            </>
          }
        >
          <Core.QuestionHeading
            question={
              <>
                <Core.LargeText fontStyle="sans" color="beige">
                  Do you see any echoes in the world from when Johns Hopkins
                  SAIS was founded to today?
                </Core.LargeText>
              </>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> Christian Herter and Paul Nitze,
              two brilliant young men, could see even in 1943 that the world was
              going to be changed and that the United States was going to play a
              role that it had never played before. The European system was
              shattered, and the international economic system had to be
              rebuilt. That’s why they created the school. As the school looks
              at its 75th anniversary, the world is facing a similar watershed,
              where we know that geopolitics has been transformed by the arrival
              of China. We know that powerful transnational forces, like climate
              change and artificial intelligence (AI), are accelerating. Liberal
              democracy in the larger sense is facing challenges that it
              hasn&rsquo;t encountered at least since the 1960s and maybe since
              the 1930s. The history of the school is in many ways tied up with
              global politics, and that&rsquo;s part of what makes it such a
              special place.
            </p>
            <p>
              <Core.LeadIn>Ashraf:</Core.LeadIn> I would definitely agree. The
              reason in my mind that students choose Johns Hopkins SAIS and the
              reason that the school plays such a vital role in how
              international affairs is shaped is that it brings a group of
              passionate and intelligent people together into one space. I touch
              on this when I talk to students. Of course, our courses do this
              too, but when you sit in a room and you talk to others, I truly
              believe those conversations, those informal interactions, shape
              how we go out into the world and how we interact. When you come
              here, you’re going to be pushed, because people aren&rsquo;t
              always going to be like-minded, but they&rsquo;re going to be
              passionate, and you engage in that way.
            </p>
            <p>
              Where we are right now, especially in the United States,
              it&rsquo;s very hard for young people to get out of their comfort
              zones and interact. And for me, that&rsquo;s where the real
              education comes from.
            </p>
          </Core.QuestionHeading>
          <Core.QuestionHeading
            question={
              <Core.LargeText fontStyle="sans" color="beige">
                The school brought together the study of economics, political
                science, diplomacy, and policy to address the problems of its
                day. How important is the study of these subjects to evaluating
                and dealing with problems international affairs face today?
              </Core.LargeText>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> The school is about to go
              through some major transformations. However, we&rsquo;re going to
              be true to our history and to some enduring features of the
              education we offer. One of those is very much a belief in the
              importance of studying history. With the Henry A. Kissinger Center
              for Global Affairs, we have integrated several eminent historians
              into our faculty.
            </p>
            <p>
              Part of the uniqueness of Johns Hopkins SAIS is that the school
              believes that, on the one hand, you need deep knowledge of the
              past and of subjects like economics, political science, and
              certainly history, and on the other hand, you bring that to bear
              on the real world and the problems of today. We’re not simply a
              school of public administration, and we&rsquo;re not simply an
              ivory tower that feels no connection to the world as it is.
            </p>
            <p>
              What we’re about is the intersection of the two. Each of the
              disciplines at the school brings different things to bear.
              Economics gives our students a certain logic of how to think about
              the world and how to think about problems. Political science is
              particularly good at helping people think about structural
              elements in international relations. What history gives you is an
              awareness of the importance of personality and contingency — that
              things can go differently.
            </p>
            <p>
              <Core.LeadIn>Ashraf:</Core.LeadIn> And at the end of the day, the
              intersections of those disciplines teach our students how to
              consider multiple factors that are shaping what is happening.
              It&rsquo;s about taking all these different factors that you may
              not think are working in conjunction and understanding that they
              play different roles. There’s no putting economics off to the side
              when considering something.
            </p>
            <p>
              I study international development, and when we’re thinking about a
              humanitarian crisis and how to respond, you are also considering
              what is the history of the government here? What is the history of
              us as American policymakers working with that government? What is
              the economic structure? It&rsquo;s never just one factor.
            </p>
          </Core.QuestionHeading>
          <Core.QuestionHeading
            question={
              <Core.LargeText fontStyle="sans" color="beige">
                You mentioned the school is going through some major
                transformations at the moment. Can you speak about a few of
                those?
              </Core.LargeText>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> The school really is at a
              watershed in a number of ways. First and foremost, the world has
              changed and is changing, and so we [Johns Hopkins SAIS], for a
              whole bunch of reasons, actually have an opportunity to change.
              Our move to 555 Pennsylvania Ave. will transform us as well.
              Churchill once said, “First we shape our buildings, and then our
              buildings shape us.” I believe that&rsquo;s true.
            </p>
            <p>
              We&rsquo;re going to change the mix of degrees that we offer to
              respond to the changing world. We&rsquo;re already in the middle
              of doing that, which involves offering very innovative programs
              while keeping our core two-year master&rsquo;s degree intact.
              We’re expanding the demographics that we touch by offering more
              hybrid education options, where you might take some coursework
              online and some coursework in person. We will be offering more
              part-time options.
            </p>
            <p>
              We have a terrific faculty, which is really the legacy of my
              predecessor. We&rsquo;re going to make sure that they&rsquo;re
              producing first-rate research. We want to make sure that their
              research is heard. The ideal faculty member is a first-rate
              scholar, a first-rate teacher, and one who is present in the
              public square. I believe those three things can be synergistic:
              one contributes to the others.
            </p>
            <p>
              We&rsquo;re also going to take a hard look at our curriculum and
              say, “Is this the right curriculum for the world that we&rsquo;re
              in?” I&rsquo;m going to lead the faculty in a broader discussion,
              with students engaged as well, to ask what it is that somebody
              graduating from Johns Hopkins SAIS in the 2020s, 2030s, and 2040s
              really needs.
            </p>
            <p>
              <Core.LeadIn>Ashraf:</Core.LeadIn> The Student Government
              Association has been thinking about this year and the upcoming
              years and what role students can play. The day that Dean Cohen
              came into his new role, he emailed us saying we&rsquo;re really
              excited to work with you, and that to me said we have an
              opportunity now to shape this transition too.
            </p>
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> And indeed, you do. I always
              used to tell my students, over the 29 years that I was a
              professor, that we hereby give you a lifetime warranty.
              That&rsquo;s how I feel about it. The truth is, not a week goes by
              without engaging the alumni, sometimes from 20 or 25 years ago.
              We’re helping them out in various ways, and they&rsquo;re helping
              us out in various ways. That should be the great joy of being part
              of an institution like this. [The relationship] does not end when
              graduates cross the stage.
            </p>
          </Core.QuestionHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={shamailaMarginalia}
                alt="Shamaila Ashraf"
              />
              <Core.Blockquote color="beige" author="Shamaila Ashraf">
                One thing that every student walks out of here with is the
                ability to take complex ideas, issues, and theories, and
                understand them, and then be able to present them to the
                audience they’re working with.
              </Core.Blockquote>
            </>
          }
        >
          <Core.QuestionHeading
            question={
              <Core.LargeText fontStyle="sans" color="beige">
                What makes up the graduate skill set that enables alumni to
                wrestle with complex challenges?
              </Core.LargeText>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> There are deep understanding
              skills and there are practical skills, and I think some of the
              best courses are those that try to combine the two. Alas, I
              can&rsquo;t teach this year, but I used to teach a course on
              strategic decision-making. Part of what I was trying to do in that
              course was both to help students understand the kinds of things
              that go into making large military decisions but also to develop
              skills by having them write short memoranda that were the kind I
              wrote for the secretary of state for a couple of years. So often
              there&rsquo;s that fusion in classes.
            </p>
            <p>
              One of the advantages that students come away with from Johns
              Hopkins SAIS is the ability to look at a problem from a holistic
              point of view and in a deep and sustained way. We live in an age
              of distraction, but we teach people how to focus and be
              disciplined in a kind of linear analysis. That is, I think, a very
              useful skill.
            </p>
            <p>
              And students learn about how the world really works. An awful lot
              of that comes down to reading, writing, and speaking. Yes,
              there&rsquo;s learning how to wield an Excel spreadsheet and
              learning about geospatial imagery analysis and how it can be put
              to use. We offer those things. But I also think we teach people
              how to read large amounts of material at different speeds, how to
              write different kinds of things — because to be successful in the
              world, you need to write differently for different audiences
              clearly and effectively — and how to speak. If you have only five
              minutes to talk about a complicated problem (which is the way the
              world works), well, how do you do that?
            </p>
            <p>
              <Core.LeadIn>Ashraf:</Core.LeadIn> One thing that every student
              walks out of here with is the ability to take complex ideas,
              issues, and theories, and understand them, and then be able to
              present them to the audience they’re working with.
            </p>
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> And bring them to bear on real
              things. Yes, Shamaila, that&rsquo;s a wonderful way of describing
              it.
            </p>
          </Core.QuestionHeading>
          <Core.QuestionHeading
            question={
              <Core.LargeText fontStyle="sans" color="beige">
                You talked about faculty members needing to be good scholars,
                good teachers, and play a role in the public square, and
                you&rsquo;ve been quite prolific in all of that over the course
                of your career. How is your own professional experience
                informing your role as dean?
              </Core.LargeText>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> The overwhelming bulk of my
              professional life has been spent here — three decades, just about.
              This place took a chance on making me a full professor when I was
              pretty young, in my early 30s, so the first and most important
              thing is that I have a very deep commitment to this place. Second,
              I would like to think that in my own career, I was able to balance
              scholarship, teaching, and presence in the public square. The
              third thing is a commitment to our students: our commitment to
              creating a professional school that is imbued with some very
              traditional values of scholarship, while also preparing students
              for the work of the world and making sure that we deliver the
              absolute best for them.
            </p>
          </Core.QuestionHeading>
          <Core.QuestionHeading
            question={
              <Core.LargeText fontStyle="sans" color="beige">
                My last question resides at this intersection of deep
                understanding of this world of distraction that we live in.
                Scholars tend to have deeper knowledge about things than what we
                read in the news. What issues do you think need to be brought
                forward and discussed more fully?
              </Core.LargeText>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> I think there are three big
              issues: climate change, AI, and liberal democracy.
            </p>
            <p>
              With climate change, my feeling has been that the partisan debate
              over it has been so fierce, and the sense of alarm, which is
              understandable, is so intense that we haven&rsquo;t said, “OK,
              well, let&rsquo;s be realistic about what we think will happen.
              What does this mean? What are the consequences?” That means being
              a little bit cold-blooded and looking at it without leaping into
              the “ought” before you know what the “is” is.
            </p>
            <p>
              Part of the challenge we have with any of these powerful
              transnational forces, which no single state can control, is that
              we tend to get two kinds of reactions. One is, “Oh, my God, look
              what&rsquo;s happening!” and to be struck by the overwhelming
              magnitude of it. The second impulse is the activist impulse — we
              have to stop it or contain it or change it. What we don&rsquo;t
              have as much of — which I believe we need and this is where the
              school comes in — is, “Let&rsquo;s first understand how this
              actually affects the world as it is. And then let&rsquo;s talk
              about what, exactly, it is that we can do about it.”
            </p>
            <p>
              <Core.LeadIn>Ashraf:</Core.LeadIn> With a lot of these big
              transnational issues that are coming up, discussions are changing
              every day. I&rsquo;m in a geo-economic strategy class right now,
              and we&rsquo;re looking at cybersecurity, economic statecraft, and
              the emerging roles of cybersecurity and bitcoin and all these
              transactional things. It&rsquo;s taught by an adjunct professor,
              and on our first day of class, he said he&rsquo;s working on these
              issues right now, and the reason he teaches this class at Johns
              Hopkins SAIS is because it&rsquo;s those of us in that classroom
              who are going to come up with some of the solutions.
            </p>
          </Core.QuestionHeading>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <div style={{ marginTop: '2em' }} />
              <Core.Blockquote color="beige" author="Eliot Cohen">
                One of the advantages that students come away with from Johns
                Hopkins SAIS is the ability to look at a problem from a holistic
                point of view and in a deep and sustained way. We live in an age
                of distraction, but we teach people how to focus and be
                disciplined in a kind of linear analysis. That is, I think, a
                very useful skill.
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={cohenShamaila}
            alt="Eliot Cohen and Shamaila Ashraf"
          />
        </Core.ContentSection>
        <Core.ContentSection>
          <p>
            <Core.LeadIn>Cohen:</Core.LeadIn> AI is an issue that, as scholars
            of international affairs, we&rsquo;re not as used to thinking
            systematically about: how technology shifts can affect international
            relations. We all have some general notions that robots are
            replacing things and there will be more self-driving vehicles. But
            how do we think this will shape international relations? Will it
            make some countries more powerful and others less? How does it
            affect globalization?
          </p>
          <p>
            And then liberal democracy, oddly enough, may be the biggest leap
            for a lot of us because we are so used to thinking about those sorts
            of issues as purely domestic and internal. Moreover, we&rsquo;re
            coming off a period, particularly from the 1980s to the present,
            with the fall of the Soviet Union and “the end of history,” in which
            liberal democracy and free enterprise as we understand all those
            things were on the march. Now I think we&rsquo;re seeing that they
            may not necessarily be on the march. And we are not used to thinking
            about this in a global way. That is going to be something
            that&rsquo;s very important for us. This reflects my own curiosity,
            but also where I want to see the school focus some of its energies.
          </p>
          <p>
            <Core.LeadIn>Ashraf:</Core.LeadIn> I work with people who are in
            groups of blockchain and all these new technologies, and
            they&rsquo;re using it in emerging markets and in development. And I
            think that&rsquo;s something that is going to become more and more
            important. The question of data security for every country is going
            to become a really important question. How are governments and big
            technology companies protecting and using our data? What does it
            mean? And then also one step further: How does someone who
            isn&rsquo;t in this niche academic circle or this highly educated
            Western society [deal with] what it all means? What does that look
            like?
          </p>
          <p>
            I&rsquo;m so excited that curricula are being updated. It&rsquo;s
            really important to think about the future of development, policy
            issues, and policy questions, and how private-sector companies play
            into it. I know from a development standpoint, often it&rsquo;s
            either that you go the government route or you go the private sector
            route. But we have PPPs [public-private partnerships], we have these
            new spaces, and what can the future of development look like when we
            as policymakers partner with the private sector? I think that will
            become more and more the norm of what we&rsquo;re seeing. Those are
            some issues that I want to start grappling with and I want our
            students to start thinking about.
          </p>
          <Core.QuestionHeading
            question={
              <Core.LargeText fontStyle="sans" color="beige">
                Dean Cohen, if you were to share a message with your students,
                what would that be?
              </Core.LargeText>
            }
          >
            <p>
              <Core.LeadIn>Cohen:</Core.LeadIn> As you do all the things that we
              ask you to do and as you do the things that you feel you need to
              do because of what you study, remember to be playful and curious.
              Take advantage of the opportunities to learn about the things that
              may not seem directly relevant but that are going to open up new
              worlds for you.
            </p>
            <p>
              I think that&rsquo;s a critical thing. It&rsquo;s easy when
              you&rsquo;re a professional school to say, “OK, follow this narrow
              path,” but that&rsquo;s not our way. The Johns Hopkins SAIS way is
              to say, “I&rsquo;m going to do all the things I need for my path,
              but I&rsquo;m going to have a wide-open, curious mind. I&rsquo;m
              going to explore some things that don&rsquo;t actually seem
              particularly relevant now — and then, who knows?”
            </p>
          </Core.QuestionHeading>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    cohenMarginalia: file(
      relativePath: { regex: $directory }
      name: { eq: "cohen-marginalia" }
    ) {
      ...MarginaliaImage
    }
    shamailaMarginalia: file(
      relativePath: { regex: $directory }
      name: { eq: "shamaila-marginalia" }
    ) {
      ...MarginaliaImage
    }
    cohenShamaila: file(
      relativePath: { regex: $directory }
      name: { eq: "cohen-shamaila" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default FeatureContent;
