import './Heading.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

const Heading: React.FC = () => {
  const { mastheadImage } = useStaticQuery<{
    mastheadImage: Core.ImageAttributes;
  }>(graphql`
    {
      mastheadImage: file(
        relativePath: {
          regex: "/issues/fall-2019/features/a-watershed-moment/images/"
        }
        name: { eq: "masthead" }
      ) {
        ...FullWidthImage
      }
    }
  `);

  return (
    <Core.Masthead color="dark" className="WatershedMomentHeading">
      <div className="WatershedMomentHeading-image">
        <Core.FullWidthImage image={mastheadImage} alt="A Watershed Moment" />
      </div>
      <Core.ContentWrapper className="WatershedMomentHeading-wrapper">
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <h1 className="WatershedMomentHeading-title">
          A Watershed
          <br />
          Moment
        </h1>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default Heading;
